<template>
  <el-card class="custom-card">
    <div style="display: flex; align-items: center;">
      <el-avatar :size="70" src="https://cf-v1.uapis.cn/favicon.ico"></el-avatar>
      <div style="margin-left: 10px;">
        <span style="font-size: 25px;">ChmlFrp-下载中心</span>
        <div>
          <span>Windows图形客户端目前有BUG，请尽量使用原生客户端二次开发的客户端。架构一般为amd64。</span>
        </div>
        <el-link type="primary" :underline="false"
          href="https://www.bilibili.com/video/BV1f14y1C7UG/">如果您是初次使用，请点击此链接查看教程！</el-link>
        <div>
          <el-link type="info" :underline="false"
            href="https://chaojixyz.lanzoub.com/b04jwt94d">下载速度慢？点击前往蓝奏云下载。访问密码：1234</el-link>
        </div>
      </div>
    </div>
    <el-row :gutter="10" style="margin-top: 50px;">
      <el-col :xs="24" :sm="12" :md="6">
        <el-card style="text-align: center;" shadow="hover" @click.native="showCard('Windows')"
          :class="{ 'card-selected': selectedOS === 'Windows' }">
          <i class="fa fa-windows" style="font-size: 60px; text-align: center; color: #409EFF;"></i>
          <el-divider></el-divider>
          <h1>Windows</h1>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6">
        <el-card style="text-align: center;" shadow="hover" @click.native="showCard('Linux')"
          :class="{ 'card-selected': selectedOS === 'Linux' }">
          <i class="fa fa-linux" style="font-size: 60px; display: inline-block; color: #e69824;"></i>
          <el-divider></el-divider>
          <h1>Linux</h1>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6">
        <el-card style="text-align: center;" shadow="hover" @click.native="showCard('freeBSD')"
          :class="{ 'card-selected': selectedOS === 'freeBSD' }">
          <i class="fa fa-chain-broken" style="font-size: 60px; display: inline-block; color:#F56C6C"></i>
          <el-divider></el-divider>
          <h1>freeBSD</h1>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="12" :md="6">
        <el-card style="text-align: center;" shadow="hover" @click.native="showCard('Darwin')"
          :class="{ 'card-selected': selectedOS === 'Darwin' }">
          <i class="fa fa-apple" style="font-size: 60px; text-align: center; color: #909399;"></i>
          <el-divider></el-divider>
          <h1>Darwin</h1>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="selectedOS === 'Windows'" v-loading="loading">
      <el-card style="margin-top: 20px;" :key="item.route" v-for="item in Windows">
        <i class="fa fa-windows" style="font-size: 18px; text-align: center; color: #409EFF;"></i>
        <el-divider direction="vertical"></el-divider>
        <span>{{ item.architecture }}</span>
        <el-divider direction="vertical"></el-divider>
        <span style="color: #909399;">{{ time }}</span>
        <el-link style="float: right; padding: 3px 0" type="primary" :href='link + item.route'>{{ link }}{{ item.route
        }}</el-link>
      </el-card>
    </div>
    <div v-if="selectedOS === 'Linux'" v-loading="loading">
      <el-card style="margin-top: 20px;" v-for="item in Linux" :key="item.route">
        <i class="fa fa-linux" style="font-size: 18px; display: inline-block; color: #e69824;"></i>
        <el-divider direction="vertical"></el-divider>
        <span>{{ item.architecture }}</span>
        <el-divider direction="vertical"></el-divider>
        <span style="color: #909399;">{{ time }}</span>
        <el-link style="float: right; padding: 3px 0" type="primary" :href='link + item.route'>{{ link }}{{ item.route
        }}</el-link>
      </el-card>
    </div>
    <div v-if="selectedOS === 'freeBSD'" v-loading="loading">
      <el-card style="margin-top: 20px;" v-for="item in freeBSD" :key="item.route">
        <i class="fa fa-chain-broken" style="font-size: 18px; display: inline-block; color:#F56C6C"></i>
        <el-divider direction="vertical"></el-divider>
        <span>{{ item.architecture }}</span>
        <el-divider direction="vertical"></el-divider>
        <span style="color: #909399;">{{ time }}</span>
        <el-link style="float: right; padding: 3px 0" type="primary" :href='link + item.route'>{{ link }}{{ item.route
        }}</el-link>
      </el-card>
    </div>
    <div v-if="selectedOS === 'Darwin'" v-loading="loading">
      <el-card style="margin-top: 20px;" v-for="item in Darwin" :key="item.route">
        <i class="fa fa-apple" style="font-size: 18px; text-align: center; color: #909399;"></i>
        <el-divider direction="vertical"></el-divider>
        <span>{{ item.architecture }}</span>
        <el-divider direction="vertical"></el-divider>
        <span style="color: #909399;">{{ time }}</span>
        <el-link style="float: right; padding: 3px 0" type="primary" :href='link + item.route'>{{ link }}{{ item.route
        }}</el-link>
      </el-card>
    </div>
  </el-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: false,
      selectedOS: 'Windows',
      link: '',
      time: '',
      Windows: [],
      Linux: [],
      freeBSD: [],
      Darwin: [],
    }
  },

  created() {
    // 在组件创建时获取一次数据
    axios.get('https://cf-v1.uapis.cn/api/dw.php')
      .then(response => {
        if (response.data && response.data.system) {
          this.Windows = response.data.system.windows;
          this.Linux = response.data.system.linux;
          this.freeBSD = response.data.system.freebsd;
          this.Darwin = response.data.system.darwin;
          this.time = response.data.up_time
          this.link = response.data.link
        }
      })
      .catch(error => {
        console.error('获取数据时发生错误:', error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    showCard(os) {
      this.selectedOS = os;
    },
  }
}
</script>

<style lang="less" scoped>
.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
}

.card-selected {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>